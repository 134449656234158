import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import ContainerBox from '../../components/container-box/container-box';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import SolidLink from '../../components/solid-link/solid-link';
import { SolidLinkColorSchemas } from '../../components/solid-link/solid-link-enum';
import StateLayout from '../../components/state-layout/state-layout';
import StateOverallScore from '../../components/states/state-overall-score/state-overall-score';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../components/svg-icon/svg-icon-enum';
import WhatToDoHome from '../../components/what-to-do-home/what-to-do-home';
import { IStateOverallTemplateProperties } from './state-overall-interface';

export default class StateOverallTemplate extends BaseComponent<IStateOverallTemplateProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateOverallTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const {
      competition,
      participation,
      polarization,
      representation,
      grandTotal,
      icon,
    } = data;
    const { breadCrumbs, state, pageContent } = pageContext;
    const {
      stateScoreDesc,
      participationDesc,
      competitionDesc,
      representationDesc,
      polarizationDesc,
      whatToDoDesc1,
      whatToDoDesc2,
      whatToDoDesc3,
      whatToDoTitle1,
      whatToDoTitle2,
      whatToDoTitle3,
      whatToDoMoreInfoTitle1,
      whatToDoMoreInfoTitle2,
      whatToDoMoreInfoTitle3,
      whatToDoMoreInfoLink1,
      whatToDoMoreInfoLink2,
      whatToDoMoreInfoLink3,
    } = pageContent;
    const stateUrl: string = generateUrlForStates(state);
    const { publicURL: iconUrl = '' } = icon.nodes[0];

    return (
      <StateLayout
        state={state}
        image={iconUrl}
        breadCrumbItems={breadCrumbs}
        title={`${this.translate('title')}(${state})`}
        description={this.translate('firstScoreDescription')}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${state} ${this.translate('title')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-overall-score-${generateUrlForStates(
            state
          )}.png`}
        />
        <ContainerBox className="c-section--score" id="overall">
          <div className="c-score">
            <StateOverallScore
              title={this.translate('scoreTitle')}
              data={grandTotal}
              description={stateScoreDesc}
            />
            <Share
              absoluteLink={`/state/${stateUrl}/overall`}
              embeddedLink={`/embedded/overall/${stateUrl}`}
              imageResourceFileName={`embedded-overall-score-${generateUrlForStates(
                state
              )}.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--overall">
          <div className="c-overall">
            <div className="c-section">
              <h3 className="c-section__title">
                {this.translate('componentScore')}
              </h3>
            </div>
            <div className="c-overall-score">
              <div className="c-overall-score__head">
                <div className="c-overall-score__title">
                  {this.translate('component')}
                </div>
                <div className="c-overall-score__title">
                  {this.translate('description')}
                </div>
                <div className="c-overall-score__title">
                  {this.translate('last5ElectionCycles')}
                </div>
                <div className="c-overall-score__title">
                  {this.translate('moreInfo')}
                </div>
              </div>
              <div className="c-overall-score__body">
                <div className="row c-overall-score__row">
                  <div className="col-lg-11 c-overall__col">
                    <StateOverallScore
                      title={this.translate('participation')}
                      data={participation}
                      description={participationDesc}
                    />
                  </div>
                  <div className="col-lg-1 c-overall__col">
                    <div className="c-overall__sub">
                      {this.translate('last5ElectionCycles')}
                    </div>
                    <SolidLink
                      colorSchema={SolidLinkColorSchemas.WHITE}
                      to={`/state/${stateUrl}/participation`}
                    >
                      <span>More info</span>
                      <SvgIcon
                        width="20"
                        viewBox="0 0 20 14"
                        paths={[
                          {
                            command: SvgIconPathCommand.ARROW_RIGHT,
                            fill: '#4A1D96',
                          },
                        ]}
                      />
                    </SolidLink>
                  </div>
                </div>
                <div className="row c-overall-score__row">
                  <div className="col-lg-11 c-overall__col">
                    <StateOverallScore
                      title={this.translate('competition')}
                      data={competition}
                      description={competitionDesc}
                    />
                  </div>
                  <div className="col-lg-1 c-overall__col">
                    <div className="c-overall__sub">
                      {this.translate('last5ElectionCycles')}
                    </div>
                    <SolidLink
                      colorSchema={SolidLinkColorSchemas.WHITE}
                      to={`/state/${stateUrl}/competition`}
                    >
                      <span>More info</span>
                      <SvgIcon
                        width="20"
                        viewBox="0 0 20 14"
                        paths={[
                          {
                            command: SvgIconPathCommand.ARROW_RIGHT,
                            fill: '#4A1D96',
                          },
                        ]}
                      />
                    </SolidLink>
                  </div>
                </div>
                <div className="row c-overall-score__row">
                  <div className="col-lg-11 c-overall__col">
                    <StateOverallScore
                      title={this.translate('representation')}
                      data={representation}
                      description={representationDesc}
                    />
                  </div>
                  <div className="col-lg-1 c-overall__col">
                    <div className="c-overall__sub">
                      {this.translate('last5ElectionCycles')}
                    </div>
                    <SolidLink
                      colorSchema={SolidLinkColorSchemas.WHITE}
                      to={`/state/${stateUrl}/representation`}
                    >
                      <span>More info</span>
                      <SvgIcon
                        width="20"
                        viewBox="0 0 20 14"
                        paths={[
                          {
                            command: SvgIconPathCommand.ARROW_RIGHT,
                            fill: '#4A1D96',
                          },
                        ]}
                      />
                    </SolidLink>
                  </div>
                </div>
                <div className="row c-overall-score__row">
                  <div className="col-lg-11 c-overall__col">
                    <StateOverallScore
                      title={this.translate('polarization')}
                      data={polarization}
                      description={polarizationDesc}
                    />
                  </div>
                  <div className="col-lg-1 c-overall__col">
                    <div className="c-overall__sub">
                      {this.translate('last5ElectionCycles')}
                    </div>
                    <SolidLink
                      colorSchema={SolidLinkColorSchemas.WHITE}
                      to={`/state/${stateUrl}/polarization`}
                    >
                      <span>More info</span>
                      <SvgIcon
                        width="20"
                        viewBox="0 0 20 14"
                        paths={[
                          {
                            command: SvgIconPathCommand.ARROW_RIGHT,
                            fill: '#4A1D96',
                          },
                        ]}
                      />
                    </SolidLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--cta">
          <WhatToDoHome
            items={[
              {
                title: whatToDoTitle1,
                description: whatToDoDesc1,
                moreInfoTitle: whatToDoMoreInfoTitle1,
                moreInfoLink: whatToDoMoreInfoLink1,
              },
              {
                title: whatToDoTitle2,
                description: whatToDoDesc2,
                moreInfoTitle: whatToDoMoreInfoTitle2,
                moreInfoLink: whatToDoMoreInfoLink2,
              },
              {
                title: whatToDoTitle3,
                description: whatToDoDesc3,
                moreInfoTitle: whatToDoMoreInfoTitle3,
                moreInfoLink: whatToDoMoreInfoLink3,
              },
            ]}
          />
        </ContainerBox>
      </StateLayout>
    );
  }
}

export const query = graphql`
  query GetStateOverallData($state: String) {
    grandTotal: allSheetDataGrandTotal(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "GrandTotal" }
      }
    ) {
      nodes {
        code
        state
        grade
        measure
        cycle
        score
        weight
      }
    }
    participation: allSheetDataParticipation(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "Participation" }
      }
    ) {
      nodes {
        code
        state
        grade
        measure
        cycle
        score
        weight
      }
    }
    competition: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "Competition" }
      }
    ) {
      nodes {
        code
        state
        grade
        measure
        cycle
        value
        score
        weight
      }
    }
    representation: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "Representation" }
      }
    ) {
      nodes {
        code
        state
        grade
        measure
        cycle
        value
        score
        weight
      }
    }
    polarization: allSheetDataPolarization(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        measure: { eq: "Polarization" }
      }
    ) {
      nodes {
        code
        state
        grade
        measure
        cycle
        value
        score
        weight
      }
    }

    icon: allFile(
      filter: {
        name: { eq: $state }
        ext: { eq: ".svg" }
        relativeDirectory: { eq: "images/states" }
      }
    ) {
      nodes {
        name
        publicURL
        relativeDirectory
      }
    }
  }
`;
