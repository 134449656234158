import React, { Fragment, ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import { Language } from '../../global/locale/locale-handler/locale-handler-enum';
import { Route } from '../../global/route-enum';
import Footer from '../footer/footer';
import SEO from '../seo/seo';
import StateHeader from '../state-header/state-header';
import { IStateLayoutProperties } from './state-layout-interface';

export default class StateLayout extends BaseComponent<IStateLayoutProperties> {
  protected declareTranslateCollection(): string {
    return 'layout';
  }

  public render(): ReactNode {
    const { image, state, title, children, breadCrumbItems } = this.props;

    return (
      <Fragment>
        <SEO
          pageTitle={`${this.translate('siteName')} - ${title}`}
          description="Page Description"
          keywords={['state', 'democracy']}
          language={Language.EN_US}
        />
        <StateHeader
          image={image}
          title={state}
          breadCrumbItems={breadCrumbItems}
          menuItems={[
            {
              title: this.translate('overall'),
              path: `/state/${generateUrlForStates(state)}${Route.OVERALL}`,
            },
            {
              title: this.translate('participation'),
              path: `/state/${generateUrlForStates(state)}${
                Route.PARTICIPATION
              }`,
            },
            {
              title: this.translate('competition'),
              path: `/state/${generateUrlForStates(state)}${Route.COMPETITION}`,
            },
            {
              title: this.translate('representation'),
              path: `/state/${generateUrlForStates(state)}${
                Route.REPRESENTATION
              }`,
            },
            {
              title: this.translate('polarization'),
              path: `/state/${generateUrlForStates(state)}${
                Route.POLARIZATION
              }`,
            },
          ]}
        />

        {children}
        <Footer
          menuItems={[
            { title: this.translate('about'), path: Route.ABOUT },
            { title: this.translate('media'), path: Route.MEDIA },
            { title: this.translate('takeAction'), path: Route.TAKE_ACTION },
            {
              title: this.translate('readTheReport'),
              path: Route.READ_THE_REPORT,
            },

            { title: this.translate('methodology'), path: Route.METHODOLOGY },
            {
              title: this.translate('data'),
              path: Route.DATA,
              isExternal: true,
            },

            {
              title: this.translate('stateOfReform'),
              path: Route.STATE_OF_REFORM,
            },
          ]}
        />
      </Fragment>
    );
  }
}
