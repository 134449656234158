import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import CycleScoreChart from '../../charts/cycle-score/cycle-score';
import { ICycleScoreChartPoint } from '../../charts/cycle-score/cycle-score-interface';
import Grade from '../../grade/grade';
import { GradeSize } from '../../grade/grade-enum';
import { IStateScoreProperties } from './state-score-interface';
import './state-score.scss';

export default class StateScore extends BaseComponent<IStateScoreProperties> {
  public render(): ReactNode {
    const {
      title = 'Overall Score',
      description,
      overallGrade,
      overallScore,
      cycleScores,
    } = this.props;

    const chartData: ICycleScoreChartPoint[] = cycleScores.map(
      (cycleScore): ICycleScoreChartPoint => {
        return {
          cycle: cycleScore.cycle,
          score: cycleScore.score,
          weight: cycleScore.weight,
        };
      }
    );

    console.log('DESC', description);
    return (
      <div className="c-state__score">
        <h3 className="c-score__title">{title}</h3>
        <div className="c-score__details">
          <div className="c-score__wrap">
            <div className="c-grade__wrap">
              <Grade grade={overallGrade} size={GradeSize.LARGE} />
              <span>{overallScore}</span>
            </div>
            {description && <div className="c-scrore__desc">{description}</div>}
          </div>
          {chartData.length > 0 ? (
            <div className="c-score__chart">
              <CycleScoreChart
                overallGrade={overallGrade}
                chartPoints={cycleScores}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
