import { Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Route } from '../../global/route-enum';
import BreadCrumb from '../bread-crumb/bread-crumb';
import ContainerBox from '../container-box/container-box';
import Menu from '../menu/menu';
import { IMenuItem } from '../menu/menu-interface';
import SvgIcon from '../svg-icon/svg-icon';
import { SvgIconPathCommand } from '../svg-icon/svg-icon-enum';
import {
  IStateHeaderProperties,
  IStateHeaderState,
} from './state-header-interface';
import './state-header.scss';
export default class StateHeader extends BaseComponent<
  IStateHeaderProperties,
  IStateHeaderState
> {
  private pathname: string;

  public constructor(properties: IStateHeaderProperties) {
    super(properties);

    this.pathname = '';
    this.state = {
      isMobileNavOpen: false,
    };
  }

  protected declareTranslateCollection(): string {
    return 'stateHeader';
  }

  public componentDidMount(): void {
    this.pathname = window.location.pathname;
  }
  private onHamburgerClick(): void {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    });
  }

  public render(): ReactNode {
    const { image, title, menuItems, breadCrumbItems } = this.props;
    const { isMobileNavOpen } = this.state;
    const mobileClassName: string = [
      's-nav js-nav',
      isMobileNavOpen ? 'is-open' : '',
    ].join(' ');

    return (
      <Fragment>
        <header className="o-section c-section--header">
          <div className="o-section__wrapper">
            <div className="c-header">
              <div className="c-header__col">
                <div className="c-logo">
                  <Link
                    to={Route.HOME}
                    className="c-link c-link--logo"
                    aria-label="State of Democracy"
                  >
                    <SvgIcon
                      width="208"
                      viewBox="0 0 208 44"
                      paths={[
                        {
                          command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                          fill: '#fff',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_EAST,
                          fill: '#EDE6FA',
                        },
                        {
                          fillRule: 'evenodd',
                          clipRule: 'evenodd',
                          command: SvgIconPathCommand.LOGO_WEST,
                          fill: '#8F60E1',
                        },
                      ]}
                    />
                  </Link>
                </div>
              </div>
              <div className="c-header__col">
                <div className="c-hamburger__wrap">
                  <div
                    className="c-hamburger"
                    onClick={(): void => {
                      this.onHamburgerClick();
                    }}
                  >
                    <input
                      className="c-hamburger__checkbox js-hamburger"
                      type="checkbox"
                      aria-label="Menu"
                    />
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                    <span className="c-hamburger__icon"></span>
                  </div>
                </div>
                <ul className={mobileClassName}>
                  <li>
                    <Link to={Route.ABOUT}>{this.translate('about')}</Link>
                  </li>
                  <li>
                    <Link to={Route.MEDIA}>{this.translate('media')}</Link>
                  </li>
                  <li>
                    <Link to={Route.TAKE_ACTION}>{this.translate('takeAction')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>

        <ContainerBox className="c-section--single">
          <BreadCrumb crumbItems={breadCrumbItems} />
          <div className="c-state-details">
            <div className="c-state-details__img">
              <img
                className="c-state-details__icon"
                width={64}
                src={image}
                alt={title}
              />
            </div>
            <h1 className="c-state-details__title">{title}</h1>
          </div>
          <Menu
            items={menuItems}
            selectedItemIndex={0}
            isActive={(item: IMenuItem): boolean => {
              return this.checkMenuActivity(item);
            }}
          />
        </ContainerBox>
      </Fragment>
    );
  }

  private checkMenuActivity(item: IMenuItem): boolean {
    const normalizedPath = this.pathname.endsWith('/')
      ? this.pathname.slice(0, -1)
      : this.pathname;
    const normalizedItemUrl = item.path.endsWith('/')
      ? item.path.slice(0, -1)
      : item.path;

    return normalizedPath === normalizedItemUrl;
  }
}
