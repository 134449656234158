import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import { ISODNode } from '../../../global/global-interfaces';
import { ICycleScoreChartPoint } from '../../charts/cycle-score/cycle-score-interface';
import StateScore from '../state-score/state-score';
import { IStateOverallScoreProperties } from './state-overall-score-interface';

export default class StateOverallScore extends BaseComponent<IStateOverallScoreProperties> {
  public render(): ReactNode {
    const { data, title, description } = this.props;
    const dataCycleScores: ICycleScoreChartPoint[] = [];
    let overall: ISODNode;

    data.nodes.forEach((node): void => {
      const { cycle, score, weight } = node;

      if (cycle === 'Overall') {
        overall = node;
      } else {
        dataCycleScores.push({
          cycle,
          score: score * 100,

          weight,
        });
      }
    });

    const { score, grade } = overall;

    return (
      <StateScore
        title={title}
        description={description}
        overallGrade={grade}
        overallScore={Math.round(score * 100)}
        cycleScores={dataCycleScores}
      />
    );
  }
}
